import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { useDispatch } from "react-redux";
import JyvModal from "./general/jyvModal";
import JyvTextField from "./general/jyvTextField";
import Loading from "./general/loading";
import JyvButton from "./general/jyvButton";
import * as API from "../utils/api";
import { navigate } from "gatsby";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

export default function SignUpModal({
  open,
  setIsOpen,
  isInviteOnly,
  updatedAttendance,
  eventId,
}) {
  const dispatch = useDispatch();
  const [signIn, setSignIn] = React.useState(false);
  const [email, setEmail] = useState("");
  const [pw, setPW] = useState("");
  const [checked, setChecked] = useState(false);
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePWChange = (e) => {
    setPW(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleAttend = async (uid, status) => {
    setIsLoading(true);

    const payload = {
      user_id: uid,
      event_id: eventId,
      status: status,
    };

    const resp = await API.updateAttendance(payload);

    navigate("/?tab=upcoming");

    setIsLoading(false);
  };
  const handleSigninAttend = async () => {
    setIsLoading(true);
    const payload = { email: email, pw: pw };
    const userId = await dispatch.auth.login(payload);
    const attendResp = isInviteOnly ? updatedAttendance : "yes";
    await handleAttend(userId, attendResp);
    setIsLoading(false);
  };

  const handleSignupAttend = async () => {
    setIsLoading(true);
    try {
      const payload = {
        email: email,
        pw: pw,
        userData: {
          phone: phone,
          name: name,
          email: email,
          signed_terms_and_conditions: checked,
        },
      };
      const newUserId = await dispatch.auth.signUp(payload);
      const attendResp = isInviteOnly ? updatedAttendance : "yes";
      await handleAttend(newUserId, attendResp);
    } catch (e) {
      let message = "There was an error";
      if (!!e.message && e.message?.length > 0) {
        message = message + e.message;
      }
      //   setSnackbarMessage(message);
      //   setSnackOpen(true);
    }
  };

  return signIn ? (
    <JyvModal open={open} onClose={() => setIsOpen(false)}>
      <Grid item style={{ marginTop: 20 }}>
        <Grid
          container
          style={{ marginBottom: 5 }}
          alignItems="center"
          justifyContent="center"
        >
          Sign In
        </Grid>

        <Grid item style={{ width: "100%", marginTop: 10 }}>
          <JyvTextField
            placeholder="Email"
            variant="filled"
            value={email}
            onChange={handleEmailChange}
          />
        </Grid>
        <Grid style={{ width: "100%", marginTop: 10 }}>
          <JyvTextField
            value={pw}
            placeholder="Password"
            variant="filled"
            onChange={handlePWChange}
            type="password"
          />
        </Grid>
      </Grid>
      <Grid item style={{ marginTop: 20 }}>
        {isLoading ? (
          <Grid
            container
            style={{ width: "100%" }}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item style={{ width: "100%" }}>
              <Loading />
            </Grid>
          </Grid>
        ) : (
          <JyvButton
            text="Sign In"
            color="confirm"
            variant="contained"
            onClick={handleSigninAttend}
          />
        )}
      </Grid>
    </JyvModal>
  ) : (
    <JyvModal open={open} onClose={() => setIsOpen(false)}>
      <Grid item style={{ marginTop: 20 }}>
        <Grid
          container
          style={{ marginBottom: 5 }}
          alignItems="center"
          justifyContent="center"
        >
          Awesome!
        </Grid>
        <Grid
          container
          style={{ marginBottom: 20 }}
          alignContent="center"
          justifyContent="center"
        >
          Finish your account to confirm
        </Grid>
        <Grid item style={{ fontSize: 12 }} onClick={() => setSignIn(true)}>
          Already have an account? Click here to sign in.
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <JyvTextField
            placeholder="Name"
            value={name}
            variant="filled"
            onChange={handleNameChange}
          />
        </Grid>
        <Grid item style={{ width: "100%", marginTop: 10 }}>
          <JyvTextField
            placeholder="Email"
            variant="filled"
            value={email}
            onChange={handleEmailChange}
          />
        </Grid>
        <Grid style={{ width: "100%", marginTop: 10 }}>
          <JyvTextField
            placeholder="Phone"
            value={phone}
            variant="filled"
            onChange={handlePhoneChange}
          />
        </Grid>
        <Grid style={{ width: "100%", marginTop: 10 }}>
          <JyvTextField
            value={pw}
            placeholder="Password"
            variant="filled"
            onChange={handlePWChange}
            type="password"
          />
        </Grid>
      </Grid>
      <Grid item style={{ marginTop: 20 }}>
        {isLoading ? (
          <Grid
            container
            style={{ width: "100%" }}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item style={{ width: "100%" }}>
              <Loading />
            </Grid>
            <Grid item style={{ marginTop: 10 }}>
              This may take a few seconds
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            style={{ width: "100%" }}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item style={{ width: "100%" }}>
              <JyvButton
                text="complete"
                color="confirm"
                variant="contained"
                onClick={handleSignupAttend}
              />
            </Grid>
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  required
                  control={
                    <Checkbox
                      onChange={() => setChecked(!checked)}
                      checked={checked}
                    />
                  }
                  label={
                    <span>
                      By checking this box, you agree to recieve text
                      notifications regarding details of this event and our{" "}
                      <a href="https://app.termly.io/document/eula/4931218e-d042-401d-9419-d7654a9d8b29">
                        terms and conditions
                      </a>
                    </span>
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item>
              <a href="https://app.termly.io/document/privacy-policy/f502d6d9-4949-4a8e-b906-0283c5f61402">
                Privacy Policy
              </a>
            </Grid>
          </Grid>
        )}
      </Grid>
    </JyvModal>
  );
}
