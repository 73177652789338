import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { useStore, useSelector, useDispatch } from "react-redux";
import Chip from "@mui/material/Chip";
import _ from "underscore";
import Grid from "@mui/material/Grid";
import * as API from "../utils/api";
import moment from "moment";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import IosShareIcon from "@mui/icons-material/IosShare";
import SEO from "../components/seo";
import JyvIcon from "../images/jyv_logo.png";
import DefaultUploadImage from "../images/DefaultUploadImage.svg";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import "@fontsource/poppins";
import "../components/app.css";

import JyvModal from "../components/general/jyvModal";
import JyvTextField from "../components/general/jyvTextField";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import TestImage1 from "../images/Rectangle 3.png";
import Calendar from "../images/calendar.svg";
import TestProfileImage from "../images/test-profile-image.jpeg";
import Layout from "../components/layout";
import { navigate } from "gatsby";
import Menu from "../components/general/menu";
import AcceptIcon from "../images/accept-button.svg";
import MaybeIcon from "../images/maybe-button.svg";
import DeclineIcon from "../images/no-button.svg";
import FilledAcceptIcon from "../images/filled-confirm.png";
import FilledMaybeIcon from "../images/filled-maybe.png";
import FilledDeclineIcon from "../images/filled-no.png";
import Loading from "../components/general/loading";
import { ref, getDownloadURL, getStorage } from "firebase/storage";
import { generateCalendarLink } from "../utils";
import SignUpModal from "../components/signUpModal";

const EventDetailsPage = (props) => {
  const eventId = queryString.parse(props.location?.search).id;
  const storage = getStorage();
  const [eventDetails, setEvent] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [open, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [path, setPath] = useState("");

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isAttendeeOpen, setAttendeeOpen] = useState(false);

  const dispatch = useDispatch();
  const numOfAttendees = eventDetails?.attendees?.filter(
    (a) => a.status === "yes"
  )?.length;
  const attendees = eventDetails?.attendees?.filter((a) => a.status === "yes");
  const isInviteOnly = eventDetails?.access === "invite-only";

  const [snackOpen, setSnackOpen] = React.useState(false);
  const [updatedAttendance, setUpdatedAttendance] = useState("");
  const [profileImageUri, setProfileImageUri] = useState(null);

  const calendarLink = generateCalendarLink(eventDetails, path);
  const isPastEvent = moment(eventDetails?.event_date).isBefore();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const bucketPath = `users/${eventDetails.creator_id}/public/profile-image`;
        const url = await getDownloadURL(ref(storage, bucketPath));
        setProfileImageUri(url);
      } catch (e) {
        console.log(e);
      }
    };
    if (!!eventDetails && !profileImageUri) {
      fetchData();
    }
  }, [eventDetails, profileImageUri, setProfileImageUri]);

  const handleClick = () => {
    setSnackOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarMessage("");
    setSnackOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  // const loggedInUser = state.auth.isLoggedIn
  const userId = useSelector((state) => state?.auth?.userId, _.isEqual);
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn, _.isEqual);
  const attendance = eventDetails?.attendees.filter((a) => a.id === userId);

  const fetchData = React.useCallback(async () => {
    try {
      setIsPageLoading(true);
      const { data } = await API.getEventDetails(eventId);
      const checkOwner = userId === data?.data?.creator_id;
      setIsOwner(checkOwner);
      setEvent(data?.data);
      setIsPageLoading(false);
    } catch (e) {
      let message = "There was an error";
      if (!!e.message && e.message?.length > 0) {
        message = message + e.message;
      }
      setSnackbarMessage(message);
      setSnackOpen(true);
    }
  }, [eventId, userId]);

  React.useEffect(() => {
    if (eventId) {
      fetchData();
    }
  }, [fetchData, eventId]);

  React.useEffect(() => {
    if (path.length === 0) {
      setPath(window.location.href);
    }
  }, [fetchData, eventId]);

  const handleAttend = async (uid, status) => {
    if (!isLoggedIn) {
      setIsOpen(true);
    } else {
      setIsLoading(true);
      try {
        const payload = {
          user_id: uid,
          event_id: eventId,
          status: status,
        };
        const resp = await API.updateAttendance(payload);

        navigate("/?tab=my-events");
      } catch (e) {
        let message = "There was an error";
        if (!!e.message && e.message?.length > 0) {
          message = message + e.message;
        }
        setSnackbarMessage(message);
        setSnackOpen(true);
      }
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      const payload = { type: "event", id: eventId };
      await API.deleteContent(payload);
      navigate("/");
    } catch (e) {
      let message = "There was an error";
      if (!!e.message && e.message?.length > 0) {
        message = message + e.message;
      }
      setSnackbarMessage(message);
      setSnackOpen(true);
      console.log(e);
    }
  };

  const renderAttendees = () => {
    return attendees?.map((a) => <Grid item>{a.display_name}</Grid>);
  };

  const editingOptions = [
    {
      label: "Edit",
      handleClick: () =>
        navigate("/create", { state: { content: eventDetails } }),
      hasIcon: false,
    },
    {
      label: "Delete",
      handleClick: handleDelete,
      hasIcon: false,
    },
  ];
  // TODO: show event not found if there is no event associated with that id

  return (
    <Layout bypassLogin={true}>
      {/* <SEO title={eventDetails?.name} imageUrl={eventDetails?.file_urls[0]} /> */}
      <SignUpModal
        open={open}
        setIsOpen={setIsOpen}
        eventId={eventId}
        isInviteOnly={isInviteOnly}
        updatedAttendance={updatedAttendance}
      />
      <JyvModal open={isAttendeeOpen} onClose={() => setAttendeeOpen(false)}>
        <Grid container direction="column">
          {renderAttendees()}
        </Grid>
      </JyvModal>
      {isPageLoading ? (
        <Grid
          container
          style={{ width: "100%", height: "100vh" }}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ width: "100%", height: "100vh" }}
          >
            <Loading />
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          style={{ width: "100%", maxWidth: 428, marginBottom: 120 }}
        >
          {isLoggedIn && (
            <Grid
              item
              onClick={() => navigate("/")}
              style={{
                width: "100%",
                backgroundColor: "black",
                color: "white",
                padding: 5,
              }}
            >
              Back
            </Grid>
          )}
          <Grid item style={{ width: "100%" }}>
            {" "}
            {/* TODO: determine media type and display appropiate one */}
            <img
              style={{
                width: "100%",
                height: "50vh",
                objectFit: "cover",
              }}
              src={
                !!eventDetails?.file_urls && eventDetails?.file_urls?.length > 0
                  ? eventDetails?.file_urls[0]
                  : eventDetails?.attachments?.length > 0
                  ? eventDetails?.attachments[0].file_url
                  : DefaultUploadImage
              }
              className="content-card-cover-image"
            />
          </Grid>
          <Grid item style={{ padding: 0 }}>
            <Grid
              container
              direction="row"
              style={{
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 20,
                width: "100%",
              }}
              justifyContent="space-between"
            >
              <Grid item>
                <Grid container direction="row" alignItems="center">
                  {/* {isOwner && (
                  <Grid item style={{ marginLeft: -7 }}>
                    <Menu options={editingOptions} isLongIcon={true} />
                  </Grid>
                )} */}
                  <Grid
                    item
                    style={{
                      marginRight: 10,
                      color: "#8D8D8D",
                      fontSize: 14,
                    }}
                  >
                    {eventDetails?.event_type}
                  </Grid>
                  <Grid item style={{ color: "#8D8D8D", fontSize: 14 }}>
                    |
                  </Grid>
                  <Grid
                    item
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      color: "#8D8D8D",
                      fontSize: 14,
                    }}
                  >
                    {eventDetails?.access}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                justifyContent="flex-end"
                container
                direction="row"
                style={{ width: "50%" }}
              >
                <Grid item>
                  <img src={Calendar} style={{ height: 14, marginRight: 5 }} />
                </Grid>
                <Grid item style={{ fontSize: 14 }}>
                  {moment(eventDetails?.event_date).format(
                    "ddd MMM DD @ h:mm A"
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-end">
              <Grid
                item
                style={{
                  marginRight: 20,
                  padding: 2,
                  paddingLeft: 10,
                  paddingRight: 10,
                  backgroundColor: "#6488EA",
                  borderRadius: 5,
                }}
              >
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  href={calendarLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Add to calendar
                </a>
              </Grid>
            </Grid>
            <Grid
              item
              style={{ fontSize: 40, paddingLeft: 20, paddingRight: 20 }}
            >
              {eventDetails?.name}
            </Grid>
            <Grid
              item
              container
              direction="row"
              justifyContent="space-between"
              style={{ paddingLeft: 20, paddingRight: 20 }}
            >
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                style={{ marginTop: 10, width: "50%" }}
                // onClick={() =>
                //   navigate(`/profile/?id=${eventDetails?.creator_id}`)
                // }
              >
                <Grid item>
                  <Avatar
                    alt={`${eventDetails?.name}-profile-image`}
                    src={profileImageUri}
                    sx={{ width: 40, height: 40 }}
                    style={{
                      marginRight: 10,
                      marginBottom: 10,
                    }}
                  />
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item style={{ fontSize: 12 }}>
                      HOSTED BY:
                    </Grid>
                    <Grid item>
                      <Grid container direction="row" alignItems="center">
                        {" "}
                        <Grid item style={{ fontSize: 12 }}>
                          {eventDetails?.creator_name}
                        </Grid>
                        {isOwner ? (
                          <Grid item>
                            <Chip
                              label="HOST"
                              color="warning"
                              style={{
                                fontSize: 10,
                                height: 18,
                                marginLeft: 10,
                              }}
                            />
                          </Grid>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {numOfAttendees !== 0 ? (
                <Grid
                  item
                  style={{
                    marginLeft: 10,
                    marginRight: 10,
                    color: "#8D8D8D",
                    fontSize: 12,
                  }}
                  onClick={() => setAttendeeOpen(true)}
                >
                  <Grid>
                    {!!numOfAttendees
                      ? `${numOfAttendees} ${
                          numOfAttendees === 1 ? "person is" : "people are"
                        } attending`
                      : "Attendance information unavailable"}
                  </Grid>
                  {/* TODO: display actuall attendee data and map it out */}
                  {/* <AvatarGroup max={4}>
                <Avatar
                  alt="Remy Sharp"
                  src={TestProfileImage}
                  sx={{ width: 20, height: 20 }}
                />
                <Avatar
                  alt="Travis Howard"
                  src={TestProfileImage}
                  sx={{ width: 20, height: 20 }}
                />
                <Avatar
                  alt="Cindy Baker"
                  src={TestProfileImage}
                  sx={{ width: 20, height: 20 }}
                />
                <Avatar
                  alt="Agnes Walker"
                  src={TestProfileImage}
                  sx={{ width: 20, height: 20 }}
                />
              </AvatarGroup> */}
                  <IosShareIcon
                    style={{ height: 18, marginTop: "-5", marginLeft: 5 }}
                    onClick={() => {
                      navigator.clipboard.writeText(path);
                      setSnackbarMessage("Shareable Link Copied");
                      setSnackOpen(true);
                    }}
                  />
                </Grid>
              ) : (
                <IosShareIcon
                  style={{ height: 20, marginTop: 15, marginLeft: 5 }}
                  onClick={() => {
                    navigator.clipboard.writeText(path);
                    setSnackbarMessage("Shareable Link Copied");
                    setSnackOpen(true);
                  }}
                />
              )}
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{ paddingLeft: 20, paddingRight: 20 }}
            >
              <Snackbar
                open={snackOpen}
                autoHideDuration={3000}
                onClose={handleClose}
                message={snackbarMessage}
                action={action}
                key={"top" + "center"}
              />
            </Grid>
            {/* TODO: hiding information so users are encouraged to download the app */}
            {!!eventDetails?.event_address || !!eventDetails?.event_link ? (
              <Grid
                container
                direction="row"
                alignItems="center"
                style={{ paddingLeft: 20, paddingRight: 20 }}
              >
                <Grid item>
                  <LocationOnIcon
                    style={{ height: 17, width: 17, marginRight: 3 }}
                  />
                </Grid>
                <a
                  href={`http://maps.google.com/?q=${
                    !!eventDetails?.event_address.address
                      ? eventDetails?.event_address.address
                      : eventDetails?.event_address
                  }`}
                  target="_blank"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  <Grid item style={{ fontSize: 12 }}>
                    {eventDetails?.event_type === "irl"
                      ? !!eventDetails?.event_address.address
                        ? eventDetails?.event_address.address
                        : eventDetails?.event_address
                      : eventDetails?.event_link}
                  </Grid>
                </a>
              </Grid>
            ) : null}
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              style={{
                marginTop: 15,
                fontSize: 12,
                padding: 5,
                width: "100%",
                backgroundColor: "#441763",
                color: "white",
                textAlign: "center",
              }}
            >
              <Grid item>Create events. Message friends. Share photos.</Grid>
            </Grid>
            {!isPastEvent && (
              <Grid item style={{ marginTop: 20, width: "100%" }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  style={{ width: "100%" }}
                >
                  <Grid item>
                    {(!!attendance && attendance[0]?.status === "yes") ||
                    isOwner ? (
                      <img
                        src={FilledAcceptIcon}
                        className="attendance-icon"
                        style={{
                          margin: 5,
                          marginRight: 10,
                          height: 35,
                          zIndex: 3,
                        }}
                        onClick={() => {
                          setUpdatedAttendance("yes");
                          handleAttend(userId, "yes");
                        }}
                      />
                    ) : (
                      <img
                        src={AcceptIcon}
                        className="attendance-icon"
                        style={{
                          margin: 5,
                          marginRight: 10,
                          height: 35,
                          zIndex: 3,
                        }}
                        onClick={
                          isOwner
                            ? null
                            : !isLoggedIn
                            ? () => {
                                setUpdatedAttendance("yes");
                                setIsOpen(true);
                              }
                            : () => {
                                setUpdatedAttendance("yes");
                                handleAttend(userId, "yes");
                              }
                        }
                      />
                    )}
                  </Grid>
                  <Grid item>
                    {!!attendance && attendance[0]?.status === "maybe" ? (
                      <img
                        src={FilledMaybeIcon}
                        className="attendance-icon"
                        style={{
                          margin: 5,
                          marginRight: 10,
                          height: 35,
                          zIndex: 3,
                        }}
                        onClick={
                          isOwner
                            ? null
                            : () => {
                                setUpdatedAttendance("maybe");
                                handleAttend(userId, "maybe");
                              }
                        }
                      />
                    ) : (
                      <img
                        src={MaybeIcon}
                        className="attendance-icon"
                        style={{
                          margin: 5,
                          marginRight: 10,
                          height: 35,
                          zIndex: 3,
                        }}
                        onClick={
                          isOwner
                            ? null
                            : !isLoggedIn
                            ? () => {
                                setUpdatedAttendance("maybe");
                                setIsOpen(true);
                              }
                            : () => {
                                setUpdatedAttendance("maybe");
                                handleAttend(userId, "maybe");
                              }
                        }
                      />
                    )}
                  </Grid>
                  <Grid item>
                    {(!!attendance && attendance[0]?.status === "no") ||
                    updatedAttendance === "no" ? (
                      <img
                        src={FilledDeclineIcon}
                        style={{ margin: 5, height: 35, zIndex: 3 }}
                        onClick={
                          isOwner
                            ? null
                            : () => {
                                setUpdatedAttendance("no");
                                handleAttend(userId, "no");
                              }
                        }
                      />
                    ) : (
                      <img
                        src={DeclineIcon}
                        style={{ margin: 5, height: 35, zIndex: 3 }}
                        onClick={
                          isOwner ? null : () => setUpdatedAttendance("no")
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/* <Grid
              item
              style={{ marginTop: 15, paddingLeft: 20, paddingRight: 20 }}
            >
              <a
                // TODO: uncomment when released to the app store
                // href={`https://jyv.app/link/?link=https://jyv.app/event-details/?id=${eventId}&ibi=org.jyv.app&isi=284708449&imv=0&efr=1`}
                href={`https://jyv.app/link/?link=https://jyv.app/event-details/?id=${eventId}&ibi=org.jyv.app&isi=6446189439&imv=0&efr=1`}
                style={{ textDecoration: "none", color: "white" }}
              >
                <JyvButton
                  text="Download the app!"
                  color="confirm"
                  variant="contained"
                  // onClick={navigateToAppStore}
                />
              </a>
            </Grid> */}
          </Grid>
          {!!eventDetails?.description && (
            <Grid
              container
              direction="column"
              style={{
                paddingLeft: 20,
                paddingRight: 20,
                backgroundColor: "#212121",
              }}
            >
              <Grid
                item
                style={{
                  marginTop: 10,
                  color: "#8D8D8D",
                  fontSize: 16,
                }}
              >
                Description
              </Grid>
              <Grid
                item
                style={{ marginTop: 10, color: "#8D8D8D", fontSize: 12 }}
              >
                {eventDetails?.description}
              </Grid>
            </Grid>
          )}
          {/* <Grid container style={{ paddingLeft: 20, paddingBottom: 20 }}>
          <Grid item>
            <Grid container direction="row" style={{ marginTop: 10 }}>
              <Grid item>
                <Chip
                  label="#herbalism"
                  color="success"
                  variant="outlined"
                  style={{ margin: 5 }}
                />
              </Grid>
              <Grid item>
                <Chip
                  label="#blackHair"
                  color="success"
                  variant="outlined"
                  style={{ margin: 5 }}
                />
              </Grid>
              <Grid item>
                <Chip
                  label="#queer"
                  color="success"
                  variant="outlined"
                  style={{ margin: 5 }}
                />
              </Grid>
              <Grid item>
                <Chip
                  label="#femme"
                  color="success"
                  variant="outlined"
                  style={{ margin: 5 }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
        </Grid>
      )}
    </Layout>
  );
};

export default EventDetailsPage;

export const Head = () => (
  <>
    <title>Jyv - An Event Management Platform</title>
    <meta name="description" content="Click to view event details" />
    <meta name="image" content={JyvIcon} />
    <meta
      property="og:description"
      content="Click to view event details"
    ></meta>
    <meta property="og:image" content={JyvIcon} />
  </>
);
